<template>
  <div>

    <!-- #region::Circuit filters -->
    <CircuitListFilters
      :circuit-number="totalCircuits + 1"
      :show-download-file="showDownloadFile"
      :can-apply-global-filters="canApplyGlobalFilters"
      @on-filter="handleFilter"
    />
    <!-- #endregion::Circuit filters -->

    <!-- #region::Skeleton -->
    <template v-if="isLoadingCircuits">
      <b-skeleton
        class="mt-1"
        animation="wave"
        width="100%"
        height="430px"
      />
    </template>
    <!-- #region::Skeleton -->

    <template v-else>
      <b-card
        class="mt-1 border border-dark shadow-none"
        no-body
      >
        <b-card-text>

          <!-- #region::Circuits list -->
          <b-table
            v-if="availableCircuits"
            :items="formartedItems"
            :fields="columns"
            stacked="md"
            class="my-0"
          >

            <!-- #region::A virtual column for name -->
            <template #cell(Name)="{ item }">
              <div class="d-flex justify-content-start">
                <span class="align-self-center text-nowrap">{{ item.Name }}</span>
                <div
                  v-if="isInTransfer(item)"
                  class="sm-banner-alert alert-pink"
                  role="alert"
                >
                  Traspaso
                </div>
              </div>
            </template>
            <!-- #endregion::A virtual column for name -->

            <!-- #region::A virtual column for prices -->
            <template #cell(prices)="{ item }">
              <span class="text-nowrap">{{ item.PriceMin | currency }} - {{ item.PriceMax | currency }}</span>
            </template>
            <!-- #endregion::A virtual column for prices -->

            <!-- #region::A virtual column for offer price -->
            <template #cell(OfferPrice)="{ item }">
              <span class="text-nowrap">{{ item.OfferPrice | currency }}</span>
            </template>
            <!-- #endregion::A virtual column for offer price -->

            <!-- #region::A virtual column for location -->
            <template #cell(location)="{ item }">
              <span
                v-if="item.Location"
                class="text-nowrap"
              >
                {{ item.Location }}
              </span>
              <span
                v-else
                class="text-nowrap"
              >No disponible</span>
            </template>
            <!-- #endregion::A virtual column for location -->

            <!-- #region::A virtual column for actions -->
            <template #cell(actions)="{ item }">

              <div class="d-flex justify-content-start">

                <!-- #region::Delete circuit button -->
                <b-button
                  v-if="showDeleteButton && $ability.can('delete', 'Circuit')"
                  v-b-tooltip.hover.top="'Eliminar'"
                  class="btn-icon rounded-circle"
                  :variant="isInTransfer(item) ? 'flat-dark' : 'flat-secondary'"
                  :disabled="isInTransfer(item)"
                  @click="onDeleteCicuit(item)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
                <!-- #endregion::Delete circuit button -->

                <!-- #region::Edit circuit button -->
                <b-button
                  v-if="showEditButton && $ability.can('edit', 'Circuit')"
                  v-b-tooltip.hover.top="'Editar'"
                  class="btn-icon rounded-circle"
                  :variant="isInTransfer(item) ? 'flat-dark' : 'flat-secondary'"
                  :disabled="isInTransfer(item)"
                  :to="{ name: 'update-circuit', params: { id: item.IdCircuit }}"
                >
                  <feather-icon icon="Edit3Icon" />
                </b-button>
                <!-- #endregion::Edit circuit button -->

                <!-- #region::Add pieces to circuit button -->
                <b-button
                  v-if="showAddToCircuitButton"
                  variant="flat-primary"
                  class="my-1 my-lg-0"
                  @click="onAddPiecesToCicuit(item)"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-50"
                  />
                  <span class="align-middle">Agregar al circuito</span>
                </b-button>
                <!-- #endregion::Add pieces to circuit button -->

              </div>

            </template>
            <!-- #endregion::A virtual column for actions -->

            <!-- #region::A virtual column for toggle button -->
            <template
              v-if="$ability.can('read', 'Circuit')"
              #cell(details)="{ detailsShowing, item }"
            >
              <div class="d-flex justify-content-end">
                <b-button
                  variant="flat-secondary"
                  class="btn-icon rounded-circle"
                  @click="toggleDetails(item)"
                >
                  <feather-icon :icon="detailsShowing ? 'ChevronUpIcon' : 'ChevronDownIcon'" />
                </b-button>
              </div>
            </template>
            <!-- #endregion::A virtual column for toggle button -->

            <!-- #region::Circuit details -->
            <template
              v-if="$ability.can('read', 'Circuit')"
              v-slot:row-details="{ item }"
            >
              <b-card
                no-body
                class="mt-1"
              >
                <CircuitsListDetails
                  v-if="item.products"
                  :id="item.IdCircuit"
                  :products="item.products"
                />
              </b-card>
            </template>
          <!-- #endregion::Circuit details -->

          </b-table>
          <!-- #endregion::Circuits list -->

          <!-- #region::Alert when no circuitos are available -->
          <b-alert
            v-if="!availableCircuits && !isLoadingCircuits"
            variant="warning"
            class="mb-0"
            show
          >
            <div class="alert-body">
              <span>No se encontraron circuitos.</span>
            </div>
          </b-alert>
          <!-- #endregion::Alert when no circuitos are available -->

        </b-card-text>
      </b-card>
    </template>

    <!-- #region begin::Pagination & items per list -->
    <BasicPaginator
      v-if="availableCircuits"
      ref="basic-paginator"
      class="mt-2"
      :total-rows="totalCircuits"
      :callback="handleChangePagination"
      :can-apply-global-pagination="canApplyGlobalPagination"
    />
    <!-- #endregion end::Pagination & items per list -->
  </div>
</template>

<script>
// #region Imports
import { mapActions, mapGetters } from 'vuex'

import {
  VBTooltip, BTable, BButton, BCard, BCardText, BSkeleton, BAlert,
} from 'bootstrap-vue'

import 'vue-good-table/dist/vue-good-table.css'
import BasicPaginator from '@/components/tables/BasicPaginator.vue'
import CircuitListFilters from '@/modules/production/circuits/components/CircuitListFilters.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import getError from '@/helpers/ErrorsHandler'
// #endregion

export default {
  name: 'CircuitsList',
  components: {
    BCard,
    BTable,
    BAlert,
    BButton,
    BCardText,
    BSkeleton,
    CircuitListFilters,
    BasicPaginator,
    CircuitsListDetails: () => import('@/modules/production/circuits/components/CircuitsListDetails.vue'),
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    // * Filter props
    showDownloadFile: {
      type: Boolean,
      default: false,
    },
    // * Table props
    showToggleButton: {
      type: Boolean,
      default: true,
    },
    showDeleteButton: {
      type: Boolean,
      default: true,
    },
    showEditButton: {
      type: Boolean,
      default: true,
    },
    showAddToCircuitButton: {
      type: Boolean,
      default: false,
    },
    /**
     * Indica si se pueden aplicar los filtros globales
     */
    canApplyGlobalFilters: {
      type: Boolean,
      default: true,
    },
    /**
     * Indica si se puede aplicar la paginación global
     */
    canApplyGlobalPagination: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      totalCircuits: 0,
      lastPage: 0,
      columns: [
        {
          key: 'IdCircuit',
          label: 'No. Circuito',
          thClass: 'bg-light text-nowrap',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Name',
          label: 'Nombre',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'prices',
          label: 'Precio',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'OfferPrice',
          label: 'Precio oferta',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'Qty',
          label: 'No. de productos',
          thClass: 'bg-light text-nowrap',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'location',
          label: 'Ubicación',
          thClass: 'bg-light',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'actions',
          label: 'Acciones',
          thClass: this.$ability.can('delete', 'Circuit') || this.$ability.can('edit', 'Circuit') ? 'bg-light' : 'd-none',
          tdClass: this.$ability.can('delete', 'Circuit') || this.$ability.can('edit', 'Circuit') ? '' : 'd-none',
          thStyle: 'text-transform: none;',
        },
        {
          key: 'details',
          label: '',
          thClass: this.$ability.can('read', 'Circuit') ? 'bg-light' : 'd-none',
          tdClass: this.showToggleButton && this.$ability.can('read', 'Circuit') ? '' : 'd-none',
        },
      ],
      circuits: [],
      selected: [],
      allOpenRows: [],
      currentItems: [],
      isLoadingCircuits: false,

      /**
       * Local pagination
       */
      localPerPage: 10,
      localCurrentPage: 1,
    }
  },
  computed: {
    ...mapGetters({
      getFilteredPerPage: 'filters/getFilteredPerPage',
      getFilteredSearchTerm: 'filters/getFilteredSearchTerm',
      getFilteredCurrentPage: 'filters/getFilteredCurrentPage',
    }),
    /**
     * Indica la cantidad de elementos por página (paginado global)
     */
    filteredPerPage: {
      get() { return this.getFilteredPerPage },
      set(value) { this.setFilteredPerPage(value) },
    },
    /**
     * Término de búsqueda filtrado (filtro en store)
     */
    filteredSearchTerm: {
      get() { return this.getFilteredSearchTerm },
      set(value) { this.setFilteredSearchTerm(value) },
    },
    /**
     * Indica la página actual (paginado global)
     */
    filteredCurrentPage: {
      get() { return this.getFilteredCurrentPage },
      set(value) { this.setFilteredCurrentPage(value) },
    },
    formartedItems() {
      if (!this.circuits) return []
      return this.circuits.map(item => {
        // eslint-disable-next-line no-underscore-dangle, no-param-reassign
        this.offRow(item)
        return item
      })
    },
    availableCircuits() {
      return this.totalCircuits > 0
    },
  },
  async created() {
    await this.loadCircuitsList()
    this.verifyContenList()
  },
  methods: {
    ...mapActions({
      loadCircuits: 'circuits/loadCircuits',
      deleteCircuit: 'circuits/deleteCircuit',
      setFilteredPerPage: 'filters/setFilteredPerPage',
      setFilteredSearchTerm: 'filters/setFilteredSearchTerm',
      setFilteredCurrentPage: 'filters/setFilteredCurrentPage',
    }),
    onRowSelected(items) {
      this.selected = items
    },
    toggleDetails(row) {
      // eslint-disable-next-line no-underscore-dangle
      if (row._showDetails) {
        this.offRow(row)
      } else {
        for (let index = 0; index < this.currentItems.length; index += 1) {
          this.offRow(this.$set(this.currentItems[index]))
        }

        this.currentItems.forEach(item => {
          this.offRow(item)
        })

        this.$nextTick(() => {
          this.onRow(row)
        })
      }
    },
    offRow(row) {
      this.$set(row, '_showDetails', false)
      this.$set(row, '_cellVariants', {
        IdCircuit: '', Name: '', prices: '', OfferPrice: '', Qty: '', location: '', actions: '', details: '',
      })
    },
    onRow(row) {
      this.$set(row, '_showDetails', true)
      this.$set(row, '_cellVariants', {
        IdCircuit: 'primary', Name: 'primary', prices: 'primary', OfferPrice: 'primary', Qty: 'primary', location: 'primary', actions: 'primary', details: 'primary',
      })
    },

    /**
    * Evento de filtrado
    * @summary Atiende el evento filtrado del componente de filtros
    * @param {Object} filter - Objeto con el tipo de filtro y el valor
    */
    async handleFilter(filter) {
      this.filteredSearchTerm = filter.value

      if (this.canApplyGlobalPagination) {
        this.filteredCurrentPage = 1
      } else {
        this.localCurrentPage = 1
      }

      // eslint-disable-next-line no-unused-expressions
      this.$refs['basic-paginator']?.resetCurrentPage()
      await this.loadCircuitsList()
      this.verifyContenList()
    },

    /**
    * Evento de paginación
    * @summary Evento del componente de paginación. Establece la página actual y la cantidad
    * de registros por página
    * @param {number} currentPage - Página actual
    * @param {number} perPage - Elementos a mostrar por página
    */
    async handleChangePagination(currentPage, perPage) {
      if (this.canApplyGlobalPagination) {
        this.filteredCurrentPage = currentPage
        this.filteredPerPage = perPage
      } else {
        this.localCurrentPage = currentPage
        this.localPerPage = perPage
      }
      this.loadCircuitsList()
    },

    /**
    * Verificación de contenido
    * @summary Verifica que la página actual tenga registros, en caso de no tenerlos
    * tomará la última página disponible y cargará los registros
    */
    async verifyContenList() {
      if (this.circuits.length === 0) {
        if (this.canApplyGlobalPagination) {
          this.filteredCurrentPage = this.lastPage
        } else {
          this.localCurrentPage = this.lastPage
        }

        // Verifica si hay registros disponibles en el servidor
        if (this.availableCircuits) {
          // eslint-disable-next-line no-unused-expressions
          this.$refs['basic-paginator']?.resetCurrentPage(this.lastPage)
          await this.loadCircuitsList()
        }
      }
    },

    /**
    * Carga de lista de circuitos
    * @summary Atiende la carga de la lista de circuitos y establece
    * los valores de la paginación
    * @exception Muestra un mensaje de error en caso de que la petición
    * al servidor falle
    */
    async loadCircuitsList() {
      try {
        this.$swal({
          title: 'Cargando...',
          allowOutsideClick: false,
        })
        this.$swal.showLoading()
        this.isLoadingCircuits = true

        const response = await this.loadCircuits(this.loadParams())
        this.circuits = response.data.circuits.circuits.data
        this.totalCircuits = response.data.circuits.circuits.total

        if (this.canApplyGlobalPagination) {
          this.filteredCurrentPage = response.data.circuits.circuits.current_page
        } else {
          this.localCurrentPage = response.data.circuits.circuits.current_page
        }

        this.lastPage = response.data.circuits.circuits.last_page

        this.isLoadingCircuits = false
        this.$swal.close()
      } catch (error) {
        this.showToast('Error de validación', getError(error), 'danger')
      } finally {
        this.$swal.close()
      }
    },

    /**
    * Eliminación de circuito
    * @summary Atiende el evento de eliminación de circuito
    * @param {Object} item - Contiene la información del circuito a eliminar
    * @exception En caso de error en la petición al servidor se muestra un mensaje
    * de advertencia
    */
    async onDeleteCicuit(item) {
      const result = await this.$swal({
        title: '¿Deseas continuar?',
        text: '¡La información del circuito será eliminada y sus productos regresarán al listado de productos!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Eliminar',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })

      if (result.isConfirmed) {
        try {
          this.$swal({
            title: 'Espere por favor',
            allowOutsideClick: false,
          })

          this.$swal.showLoading()

          await this.deleteCircuit(item.IdCircuit)
          await this.loadCircuitsList()
          await this.verifyContenList()

          this.$swal.fire('Eliminado', '¡El circuito ha sido eliminado correctamente!', 'success')
        } catch (error) {
          this.$swal.close()
          this.showToast('Error de validación', getError(error), 'danger')
        }
      }
    },

    /**
    * Evento para agregar piezas al circuito
    * @summary Atiende el evento cuando se seleccionan piezas de un producto
    * para agregarlas al circuito
    * @param {Object} item - Contiene la información del producto
    */
    onAddPiecesToCicuit(item) {
      this.$emit('on-selected-circuit', item)
    },

    /**
    * Parámetros de carga
    * @summary Devuelve los parámetros de carga de la lista
    */
    loadParams() {
      return {
        perPage: this.canApplyGlobalPagination ? this.filteredPerPage : this.localPerPage,
        resource: 'circuits',
        searchTerm: this.filteredSearchTerm,
        currentPage: this.canApplyGlobalPagination ? this.filteredCurrentPage : this.localCurrentPage,
      }
    },

    /**
    * Circuito en orden traspaso
    * @summary Verifica si un circuito se encuentra en una orden de traspaso
    * @param {object} circuito - Contiene la información del circuito
    */
    isInTransfer(circuito) {
      return circuito.Transfer && circuito.Transfer === 'Si'
    },

    /**
    * Muestra un mensaje de tipo toast
    * @param {String} title - Título del mensaje
    * @param {String} text - Contenido del mensaje
    * @param {String} variant - Tipo del mensaje (warning, success, danger)
    */
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title, text, variant, icon: 'BellIcon',
        },
      })
    },
  },
}
</script>
